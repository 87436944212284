import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './app';

import './styles/styles.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <App/>
);


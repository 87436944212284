import React from 'react';

import Router from './Router/Router';


import './styles/styles.css';

function App() {
  return (
    <div>
      <Router />
    </div>
  );
}

export default App;

function MiEvento (){

    return(
        <>
            <h3>Holi</h3>       
        </>
    )
};

export default MiEvento;